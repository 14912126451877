export const customerUpdateMutation = `
mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
      customer {
        id
        lastName
        firstName
        acceptsMarketing
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerGenderMutation = `
mutation updateCustomerMetafields($input: CustomerInput!) {
  customerUpdate(input: $input) {
    userErrors {
      field
      message
    }
  }
}
`;