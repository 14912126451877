export default {
  title: 'Connect a TV',
  description:
    'Enter the code displayed on your TV to link it with your On Air account',
  error:
    'The code you entered isn’t recognised. Double-check the code displayed on your TV, or select “Get a new code” and try again',
  details: 'Find the code on the log in page of the On Air Smart TV app',
  connect_button: 'Connect device',
  successful_connection: 'Success! Your account is now connected to your TV',
};
