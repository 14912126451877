export default {
  subtitles: 'Subtitles',
  audio: 'Audio',
  back: 'back',
  loading_messages: [
    'Tuning the guitar strings',
    'Rosin the violin bow',
    'Practising the vocal scales',
    'Warming up the fingers',
    'Mic check 1, 2, testing testing',
    'Loading in the band’s backline',
    'Unloading the tour bus',
    'Perfecting the harmony',
    'Turning the volume up to 11',
    'Locating the mic stand',
  ],
};
