import { CustomUi } from 'bitmovin-player-react';
import {
  AirPlayToggleButton,
  AudioTrackListBox,
  BufferingOverlay,
  CastStatusOverlay,
  CastToggleButton,
  Component,
  Container,
  ControlBar,
  ErrorMessageOverlay,
  FullscreenToggleButton,
  PictureInPictureToggleButton,
  PlaybackTimeLabel,
  PlaybackTimeLabelMode,
  PlaybackToggleButton,
  PlaybackToggleOverlay,
  SeekBar,
  SeekBarLabel,
  SettingsPanel,
  SettingsPanelItem,
  SettingsPanelPage,
  SettingsPanelPageBackButton,
  SettingsPanelPageOpenButton,
  SettingsToggleButton,
  Spacer,
  SubtitleListBox,
  SubtitleOverlay,
  UIContainer,
  VolumeSlider,
  VolumeToggleButton,
} from 'bitmovin-player-ui';

// TODO OAR-6156: Uncomment this code once subtitles bug is solved
// import messages from './messages';

// const mainSettingsPanelPage = new SettingsPanelPage({
//   components: [],
// });

// const settingsPanel = new SettingsPanel({
//   components: [mainSettingsPanelPage],
//   hidden: true,
//   pageTransitionAnimation: false,
// });

// const subtitleSettingsPanelPage = new SettingsPanelPage({
//   components: [
//     new SettingsPanelItem(
//       '',
//       new SettingsPanelPageBackButton({
//         container: settingsPanel,
//         text: messages.back,
//       }),
//     ),
//     new SettingsPanelItem(messages.subtitles, new SubtitleListBox(), {
//       cssClasses: ['settings-list'],
//     }),
//   ],
// });

// const subtitleSettingsOpenButton = new SettingsPanelPageOpenButton({
//   targetPage: subtitleSettingsPanelPage,
//   container: settingsPanel,
//   text: messages.subtitles,
//   cssClasses: ['subtitles'],
// });

// const audioSettingsPanelPage = new SettingsPanelPage({
//   components: [
//     new SettingsPanelItem(
//       '',
//       new SettingsPanelPageBackButton({
//         container: settingsPanel,
//         text: messages.back,
//       }),
//     ),
//     new SettingsPanelItem(messages.audio, new AudioTrackListBox(), {
//       cssClasses: ['settings-list'],
//     }),
//   ],
// });

// const audioSettingsOpenButton = new SettingsPanelPageOpenButton({
//   targetPage: audioSettingsPanelPage,
//   container: settingsPanel,
//   text: messages.audio,
//   cssClasses: ['audio'],
// });

// mainSettingsPanelPage.addComponent(
//   new SettingsPanelItem('', subtitleSettingsOpenButton),
// );
// mainSettingsPanelPage.addComponent(
//   new SettingsPanelItem('', audioSettingsOpenButton),
// );
// settingsPanel.addComponent(subtitleSettingsPanelPage);
// settingsPanel.addComponent(audioSettingsPanelPage);

const uiContainerFactory = () =>
  new UIContainer({
    components: [
      new SubtitleOverlay(),
      new CastStatusOverlay(),
      new ErrorMessageOverlay(),
      new PlaybackToggleOverlay(),
      new BufferingOverlay({
        components: [new Component({ cssClasses: ['loader'] })],
      }),
      new ControlBar({
        components: [
          // settingsPanel,
          new Container({
            components: [new SeekBar({ label: new SeekBarLabel() })],
            cssClasses: ['controlbar-top'],
          }),
          new Container({
            components: [
              new PlaybackToggleButton(),
              new VolumeToggleButton(),
              new VolumeSlider(),
              new Container({
                components: [
                  new PlaybackTimeLabel({
                    timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
                    hideInLivePlayback: true,
                  }),
                  new PlaybackTimeLabel({
                    timeLabelMode: PlaybackTimeLabelMode.TotalTime,
                  }),
                ],
                cssClasses: ['time-labels'],
              }),
              new Spacer(),
              new AirPlayToggleButton(),
              new CastToggleButton(),
              // new SettingsToggleButton({ settingsPanel }),
              new PictureInPictureToggleButton(),
              new FullscreenToggleButton(),
            ],
            cssClasses: ['controlbar-bottom'],
          }),
        ],
      }),
    ],
  });

export const customUi: CustomUi = {
  containerFactory: uiContainerFactory,
};
