import React, { useState } from 'react';
import type { FC } from 'react';
import { template } from 'lodash';
import { FormProvider } from 'react-hook-form';
import type { FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import { ButtonType } from '@/enums/ButtonType';
import { deletePersonalDataCategory } from '@/enums/Freshdesk';
import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from '@/errors';
import { commonTexts } from '@/messages/common';
import { AuthCustomerInfo } from '@/reducers/authSlice';
import { NotificationObject } from '@/types/Notification';
import { isObjectEmpty } from '@/utils/object';
import ROUTES from '../../../../constants/routes';
import type { LinkBase } from '../../../../types/link';
import { charactersAndSpacesRegex } from '../../../../utils/form';
import { navigate } from '../../../../utils/navigation';
import Button from '../../../Button';
import Checkbox from '../../../Checkbox';
import { CONTACT_PAGE_MESSAGES } from '../../../contact/messages';
import DateInput from '../../../date-input/DateInput';
import Dropdown from '../../../dropdown/Dropdown';
import Input from '../../../input';
import LinkInternal from '../../../LinkInternal';
import OnAirNotification from '../../../OnAirNotification';
import myPlaceMessages from '../../messages';
import MyPlaceTitle from '../../MyPlaceTitle';
import type { SerializedFormDataType } from './ChangeProfileTypes';
interface ProfileSettingsComponentProps {
  readonly notification?: NotificationObject;
  readonly onChangeForm: () => void;
  readonly formRef: React.RefObject<HTMLFormElement>;
  readonly validationErrors: Record<string, any>;
  readonly customerInfo?: AuthCustomerInfo;
  readonly acceptsMarketing: boolean;
  readonly setAcceptsMarketing: React.Dispatch<React.SetStateAction<boolean>>;
  readonly dateOfBirth?: string;
  readonly links: LinkBase[];
  readonly serializedFormData: SerializedFormDataType;
  readonly onSubmitForm: () => void;
  readonly registerInput: UseFormRegister<FieldValues>;
  readonly isLoading?: boolean;
  readonly formMethods: UseFormReturn<FieldValues, object>;
}
const ProfileSettingsComponent: FC<ProfileSettingsComponentProps> = ({
  notification,
  onChangeForm,
  formRef,
  customerInfo,
  acceptsMarketing,
  setAcceptsMarketing,
  onSubmitForm,
  isLoading,
  links,
  serializedFormData,
  registerInput,
  validationErrors,
  formMethods
}) => {
  const [selectedGender, setSelectedGender] = useState<number>([...commonTexts.genderValue].indexOf(customerInfo?.gender || '')); // Default gender value

  // Handle the gender selection change

  const handleGenderChange = (value: number) => {
    formMethods.setValue('gender', value);
    setSelectedGender(value);
    onChangeForm(); // Trigger form change to update the form data
  };
  return <div data-sentry-component="ProfileSettingsComponent" data-sentry-source-file="ChangeProfileComponent.tsx">
      <MyPlaceTitle data-sentry-element="MyPlaceTitle" data-sentry-source-file="ChangeProfileComponent.tsx">
        {myPlaceMessages.profile_settings.change_profile.title}
      </MyPlaceTitle>
      {notification && <OnAirNotification className="mb-3" text={notification.text} type={notification.type} />}
      <FormProvider {...formMethods} data-sentry-element="FormProvider" data-sentry-source-file="ChangeProfileComponent.tsx">
        <form autoComplete="off" onChange={onChangeForm} ref={formRef} onSubmit={onSubmitForm} className="m-0">
          <div className="d-flex flex-column flex-lg-row">
            <Input className="flex-1 mb-1 me-lg-3" onEnter={onSubmitForm} label={commonTexts.first_name} icon="oa-user" defaultValue={customerInfo?.firstName} autoGeneratedProps={registerInput('firstName', {
            required: {
              value: true,
              message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
                field: commonTexts.first_name
              })
            },
            pattern: {
              value: charactersAndSpacesRegex,
              message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid])({
                field: commonTexts.first_name
              })
            }
          })} error={validationErrors.firstName?.message} testId="firstNameInput" data-sentry-element="Input" data-sentry-source-file="ChangeProfileComponent.tsx" />
            <Input className="flex-1 mb-1" onEnter={onSubmitForm} label={commonTexts.last_name} defaultValue={customerInfo?.lastName} autoGeneratedProps={registerInput('lastName', {
            required: {
              value: true,
              message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
                field: commonTexts.last_name
              })
            },
            pattern: {
              value: charactersAndSpacesRegex,
              message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid])({
                field: commonTexts.last_name
              })
            }
          })} error={validationErrors.lastName?.message} testId="lastNameInput" data-sentry-element="Input" data-sentry-source-file="ChangeProfileComponent.tsx" />
          </div>

          <Input className="mb-2" label={commonTexts.email_address} icon="oa-email" link={{
          text: myPlaceMessages.profile_settings.change_profile.change_email_link,
          to: `${ROUTES.myPlace}${ROUTES.profileSettings}${ROUTES.changeEmail}`
        }} defaultValue={customerInfo?.email} disabled data-sentry-element="Input" data-sentry-source-file="ChangeProfileComponent.tsx" />

          <span className="body-text uppercase-first-letter">
            {commonTexts.date_of_birth}
          </span>

          <div className="d-flex mb-2">
            <DateInput className="w-100" defaultValue={customerInfo?.dateOfBirth} disabled data-sentry-element="DateInput" data-sentry-source-file="ChangeProfileComponent.tsx" />
          </div>

          <Dropdown inputName="gender" options={[...commonTexts.genderValue]} label={commonTexts.gender} selected={selectedGender} error={validationErrors.gender?.message as string} selectCallback={e => handleGenderChange(e)} data-sentry-element="Dropdown" data-sentry-source-file="ChangeProfileComponent.tsx" />

          <Dropdown options={['Europe/Amsterdam (CEST)']} selected={0} label={commonTexts.timezone} disabled inputName="timezone" data-sentry-element="Dropdown" data-sentry-source-file="ChangeProfileComponent.tsx" />

          <Checkbox className="mt-2" id="register-marketing" label={commonTexts.subscribe_to_news} defaultChecked={acceptsMarketing} onChange={e => setAcceptsMarketing(e.target.checked)} name="acceptsMarketing" testId="acceptsMarketingCheckbox" data-sentry-element="Checkbox" data-sentry-source-file="ChangeProfileComponent.tsx" />

          <Button className="w-100 mt-3" onClick={onSubmitForm} disabled={isObjectEmpty(serializedFormData)} loading={isLoading} data-sentry-element="Button" data-sentry-source-file="ChangeProfileComponent.tsx">
            {myPlaceMessages.profile_settings.change_profile.primary_cta}
          </Button>
        </form>
      </FormProvider>

      <div className="d-flex flex-column mt-4">
        {links.map(({
        text,
        to,
        icon,
        type
      }) => <LinkInternal to={to} className="mb-3 body-text d-flex" type={type} key={text} icon={icon}>
            {text}
          </LinkInternal>)}
        <div>
          <Button type={ButtonType.BUTTON_STYLED_IN_COMPONENT} className="unstyled-button body-text link link-primary d-flex my-place__request-account-deletion-button" icon="oa-close" onClick={() => navigate(`${ROUTES.contact}?category=${deletePersonalDataCategory.type}&subject=${encodeURIComponent(CONTACT_PAGE_MESSAGES.delete_account.subject)}&message=${encodeURIComponent(CONTACT_PAGE_MESSAGES.delete_account.description)}`)} data-sentry-element="Button" data-sentry-source-file="ChangeProfileComponent.tsx">
            {myPlaceMessages.profile_settings.change_profile.request_account_deletion_link}
          </Button>
        </div>
      </div>
    </div>;
};
export default ProfileSettingsComponent;