import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ShopifyCustomerAccessToken,
  ShopifyCustomerResponse,
  ShopifyCustomerUpdatePayload,
} from '@/types/Shopify';
import fetchShopify from '@/utils/fetchShopify';
import { getCustomerErrorMessage } from '../errors/utils';
import { profileMessages } from '../messages/profile';
import {
  customerGenderMutation,
  customerUpdateMutation,
} from '../queries/profile';

export enum ProfileActionTypes {
  changeProfile = 'profile/changeProfile',
  changeEmail = 'profile/changeEmail',
  changePassword = 'profile/changePassword',
}

export const customerUpdateImplementation = async (
  payload: {
    customerAccessToken: ShopifyCustomerAccessToken['accessToken'];
    customer: ShopifyCustomerUpdatePayload;
  },
  rejectWithValue: any,
) => {
  const { customerAccessToken, customer } = payload;
  const field = Object.keys(customer)[0] ?? undefined;
  const { gender, id, ...customerWithoutGender } = customer;
  const { errors, data }: ShopifyCustomerResponse = await fetchShopify({
    query: customerUpdateMutation,
    queryVariables: {
      customer: customerWithoutGender,
      customerAccessToken,
    },
  });

  if (errors?.length || data?.customerUpdate?.customerUserErrors?.length > 0) {
    const errorMessage = getCustomerErrorMessage({
      defaultErrorMessage: profileMessages.error_updating_profile,
      errors,
      errorCode: data?.customerUpdate?.customerUserErrors?.[0].code,
      field,
    });
    return rejectWithValue(errorMessage);
  }

  if (gender) {
    const metafields = [
      {
        namespace: 'onair',
        key: 'gender',
        value: gender,
        type: 'single_line_text_field',
      },
    ];

    const metafieldsPayload = {
      input: {
        id: `gid://shopify/Customer/` + customer.id,
        metafields,
      },
    };

    const genderUpdateResponse = await fetchShopify({
      query: customerGenderMutation,
      queryVariables: metafieldsPayload,
      isAdminAPI: true,
    });

    if (
      genderUpdateResponse.errors?.length ||
      genderUpdateResponse.data?.customerUpdate?.userErrors?.length > 0
    ) {
      const errorMessage = getCustomerErrorMessage({
        defaultErrorMessage: profileMessages.error_updating_profile,
        errors: genderUpdateResponse.errors,
        errorCode:
          genderUpdateResponse.data?.customerUpdate?.userErrors?.[0].code,
        field: 'gender',
      });
      return rejectWithValue(errorMessage);
    }
  }

  return {
    ...data.customerUpdate.customer,
    email: customer.email,
    ...(gender ? { gender } : {}),
  };
};

export const changeProfileAction = createAsyncThunk(
  ProfileActionTypes.changeProfile,
  async (
    payload: {
      customerAccessToken: ShopifyCustomerAccessToken['accessToken'];
      customer: ShopifyCustomerUpdatePayload;
    },
    { rejectWithValue },
  ) => {
    return customerUpdateImplementation(payload, rejectWithValue);
  },
);

export const changeEmailAction = createAsyncThunk(
  ProfileActionTypes.changeEmail,
  async (
    payload: {
      customerAccessToken: ShopifyCustomerAccessToken['accessToken'];
      customer: ShopifyCustomerUpdatePayload;
    },
    { rejectWithValue },
  ) => {
    // TODO: Remove this any
    return customerUpdateImplementation(payload, rejectWithValue);
  },
);

export const changePasswordAction = createAsyncThunk(
  ProfileActionTypes.changePassword,
  async (
    payload: {
      customerAccessToken: ShopifyCustomerAccessToken['accessToken'];
      customer: ShopifyCustomerUpdatePayload;
    },
    { rejectWithValue },
  ) => {
    // TODO: Remove this any
    return customerUpdateImplementation(payload, rejectWithValue);
  },
);
