'use client';

import { ComponentProps, useEffect, useState } from 'react';

import { DRMConfig } from 'bitmovin-player';

import { useAppSelector } from '@/state/hooks';
import { getEnvironmentVariableServerSide } from '@/utils/server-functions/getEnvServerSide';
import VideoPlayer from './index';

type Params = Pick<
  ComponentProps<typeof VideoPlayer>,
  'sku' | 'previewCredentials'
>;

export const useDrmConfig = (params: Params) => {
  const jwt = useAppSelector((state) => state.auth.token?.jwt);
  const [config, setConfig] = useState<DRMConfig>();

  useEffect(() => {
    const generateAndSetConfig = async () => {
      const drmConfig = await generateDrmConfig({ ...params, jwt });
      setConfig(drmConfig);
    };
    generateAndSetConfig();
  }, []);

  return config;
};

/**
 * @description Generates a DRMConfig that can be used by Bitmovin to apply DRM protection.
 * There are two scenarios where we want to acquire a license:
 * - A user that purchased a ticket wants to watch the show. In that case, a jwt and a sku are necessary to validate that he owns the ticket.
 * - An artist wants to preview their show, they can do it through the preview page. In that case a preview page id and access code are necessary to validate their identity.
 */
const generateDrmConfig = async ({
  jwt,
  sku,
  previewCredentials,
}: Params & { jwt?: string }): Promise<DRMConfig | undefined> => {
  if (!((sku && jwt) || previewCredentials)) {
    return undefined;
  }

  const { DRM_ENDPOINT, KEYOS_FAIRPLAY_CERT_URL } =
    await getEnvironmentVariableServerSide([
      'DRM_ENDPOINT',
      'KEYOS_FAIRPLAY_CERT_URL',
    ]);

  let url = `${DRM_ENDPOINT}/generate-auth-xml/license-acquisition`;
  if (sku && jwt) {
    url += `?sku=${sku}&jwt=${jwt}`;
  } else if (previewCredentials) {
    url += `?preview=true&sku=${previewCredentials.id}&accessToken=${previewCredentials.accessCode}`;
  }

  return {
    preferredKeySystems: ['widevine', 'playready', 'fairplay'],
    fairplay: {
      certificateURL: KEYOS_FAIRPLAY_CERT_URL,
      LA_URL: `${url}&drmType=fairplay`,
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
      },
      prepareContentId: (contentId) => {
        const idx = contentId.indexOf('skd://');
        if (idx > -1) {
          return contentId.substring(8, 40);
        }
        throw 'Invalid Content ID format. The format of the Content ID must be the following: skd://xxx where xxx is the Key ID in hex format.';
      },
      prepareMessage: (event, session) =>
        `spc=${event.messageBase64Encoded}&assetId=${session.contentId}`,
    },
    widevine: {
      LA_URL: `${url}&drmType=widevine`,
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
      },
    },
    playready: {
      LA_URL: `${url}&drmType=playready`,
    },
  };
};
