/* eslint-disable @typescript-eslint/no-unsafe-assignment */
'use client';

import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { isPortrait } from '@/hooks/useIsPortrait';
import { useGeoLocation } from '@/providers/leaf/geo-location';
import { ContentfulEventHero } from '@/types/contentful';
import { getCheapestEventVariant, getEarliestEventVariant } from '@/utils/events';
import { formatDisplayPrice } from '@/utils/i18n';
import useEventIcons from '../../hooks/useVariantIconsPick';
import { useAppSelector } from '../../state/hooks';
import HeroComponent from '../hero/HeroComponent';
import HeroEventInfo from '../hero/HeroEventInfo';
const EventHero: FC<{
  eventHero: ContentfulEventHero;
}> = ({
  eventHero
}) => {
  // Grab the cheapest and earliest for the hero
  const earliestVariant = getEarliestEventVariant(eventHero.event.variants);
  const cheapestVariant = getCheapestEventVariant(eventHero.event.variants);
  const {
    breakpoint,
    breakpointSmallerThan
  } = useBreakpoint();
  const mobileBackgroundImageProps = useMemo(() => ({
    imageUrl: eventHero.event.portraitPoster?.asset ? (eventHero.event.portraitPoster?.asset as any)?.url! : (eventHero.event.poster?.asset as any)?.url!,
    alt: eventHero.event.portraitPoster?.alt ? eventHero.event.portraitPoster?.alt! : eventHero?.background?.asset?.title!
  }), [eventHero]);
  const backgroundImageProps = useMemo(() => ({
    imageUrl: (eventHero.background?.asset as any)?.url!,
    alt: eventHero?.background?.alt
  }), [eventHero]);
  const [backgroundProps, setBackgroundProps] = useState(backgroundImageProps);
  const customerCountryCode = useGeoLocation();
  const {
    events: purchasedEvents
  } = useAppSelector(({
    tickets
  }) => tickets);
  const isHeroEventPurchased = purchasedEvents.some(purchasedEvent => purchasedEvent.slug === eventHero.event.slug);

  // Format value of cheapest
  const cheapestPrice = cheapestVariant?.price && formatDisplayPrice(customerCountryCode, cheapestVariant.price);
  const heroEventIcons = useEventIcons(eventHero?.event);
  useEffect(() => {
    if (breakpointSmallerThan('lg') && isPortrait()) {
      setBackgroundProps(mobileBackgroundImageProps);
    } else {
      setBackgroundProps(backgroundImageProps);
    }
  }, [backgroundImageProps, breakpoint, breakpointSmallerThan, isPortrait, mobileBackgroundImageProps]);
  return <HeroComponent backgroundImageProps={backgroundProps} theme={eventHero.theme} data-sentry-element="HeroComponent" data-sentry-component="EventHero" data-sentry-source-file="index.tsx">
      <HeroEventInfo eventInfoLogo={eventHero.eventInfoLogo}
    // Either use the hero description or the simpleEvents short description
    description={eventHero.description ?? eventHero.event.shortDescription} eventSlug={eventHero.event.slug} hasTrailer={!!eventHero.event.blocks?.find(block => block.__type === 'videoBlock')} earliestVariant={earliestVariant} isHeroEventPurchased={isHeroEventPurchased} price={cheapestPrice} eventIcons={heroEventIcons} theme={eventHero.theme} eventType={eventHero.event.type} data-sentry-element="HeroEventInfo" data-sentry-source-file="index.tsx" />
    </HeroComponent>;
};
export default EventHero;