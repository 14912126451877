'use client';

import { useGeoLocation } from '@/providers/leaf/geo-location';
import { Event, EventBlock } from '@/types/contentful';
import { ExtendedContentfulLink } from '../types/link';
import { generateShareInSocialsLinks } from '../utils/social';

/**
 * Adds Segment analytics implementation to the onClick property of each artist link.
 *
 * @param links - An array of artist links with ExtendedContentfulLink type.
 * @param event - The Event object for tracking analytics data.
 * @param customerCountryCode - The country code of the customer for analytics purposes.
 * @param isProductShare - A flag indicating whether the links are for product sharing.
 * @returns {ExtendedContentfulLink[]} - An array of artist links with updated onClick properties including Segment analytics tracking.
 */
export const addAnalyticsToLinks = ({
  links,
  event,
  customerCountryCode,
  isProductShare,
}: {
  links: ExtendedContentfulLink[];
  event: Event;
  customerCountryCode: string;
  isProductShare: boolean;
}): ExtendedContentfulLink[] =>
  links?.map((link) => {
    const onClickWithTrack = (e: MouseEvent) => {
      const trackString = isProductShare ? 'Product Shared' : 'Socials Clicked';
      // Invoke the original onClick function, if available.
      if (link.onClick) {
        link.onClick(e);
      }
    };

    return { ...link, onClick: onClickWithTrack };
  });

/**
 * A custom hook that adds Segment Analytics tracking to links in an EventBlock.
 * The hook analyzes the type of block and adds analytics to the appropriate links.
 *
 * @param block - The EventBlock.
 * @param event - The Event object for tracking analytics data.
 * @returns {ExtendedContentfulLink[]} - An array of links with Segment Analytics tracking added.
 */
export const useAddAnalyticsToEventLinks = ({
  block,
  event,
}: {
  block: EventBlock;
  event: Event;
}): ExtendedContentfulLink[] => {
  const customerCountryCode = useGeoLocation();
  // Check if the block is a contentBlock.
  // eslint-disable-next-line no-underscore-dangle
  if (block.__type === 'contentBlock') {
    // Determine whether to include share social icons based on the block.shouldShowSocialButtons flag.
    const shouldUseShareSocialIcons = block.shouldShowSocialButtons;
    // Generate an array of share social icons links or an empty array.
    const defaultShareIcons = shouldUseShareSocialIcons
      ? generateShareInSocialsLinks()
      : [];
    // Get the fetched links from the block or use an empty array if no links exist.
    const blockLinks = block.links || [];
    // Merge the generated share social icons and fetched links, and add analytics to them.
    const blockLinksWithAnalytics = addAnalyticsToLinks({
      isProductShare: shouldUseShareSocialIcons,
      event,
      customerCountryCode,
      links: [...defaultShareIcons, ...blockLinks],
    });
    return blockLinksWithAnalytics;
  }
  // If the block is not a contentBlock, return an empty array since there are no links to process.
  return [];
};
