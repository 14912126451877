'use client';

import React, { useState } from 'react';
import type { FC } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { ContentfulGallery } from '@/types/contentful';
import { Dialog, DialogTrigger } from '../-core/dialog';
import Lightbox from '../-ui/lightbox';
interface Props {
  gallery: ContentfulGallery;
  isFirstBlock: boolean;
}
const Gallery: FC<Props> = ({
  gallery: {
    images,
    preserveAspectRatio
  },
  isFirstBlock
}) => {
  const [selectedImage, setSelectedImage] = useState(0);
  return <Dialog data-sentry-element="Dialog" data-sentry-component="Gallery" data-sentry-source-file="index.tsx">
      <div className="container--vertical-padding container">
        <div className={classNames('gallery', {
        [`gallery-${images.length}`]: images.length,
        'first-block': isFirstBlock
      })}>
          {images.map(({
          asset,
          alt
        }, index) => {
          const imageUrl = asset?.url;
          if (!imageUrl) return null;
          return <DialogTrigger key={imageUrl} className={`gallery__item gallery-${images.length}__item`} onClick={() => setSelectedImage(index)}>
                <div className="image-container">
                  <Image fill key={asset.file?.url} objectFit={preserveAspectRatio ? 'contain' : 'cover'} layout="fill" src={imageUrl} alt={alt} />
                </div>
              </DialogTrigger>;
        })}
        </div>
      </div>
      <Lightbox images={images} startIndex={selectedImage} data-sentry-element="Lightbox" data-sentry-source-file="index.tsx" />
    </Dialog>;
};
export default Gallery;