'use client';

import { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ErrorMessageBanner } from '@/components/banner';
import createStore from '../state/createStore';
// ...
import CartLoadProvider from './leaf/cart';
import ElevarProvider from './leaf/elevar';
import GeoLocationProvider from './leaf/geo-location';
import PreviewModeProvider from './leaf/preview-mode';
import SyncTokenProvider from './leaf/sync-token';
import TicketLoadProvider from './leaf/tickets';
import UserIdentifyProvider from './leaf/user-identify';
interface Props {
  geoLocation?: string | null;
}
const store = createStore();
const ProviderTree = ({
  geoLocation,
  children
}: PropsWithChildren<Props>) => {
  return <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-component="ProviderTree" data-sentry-source-file="index.tsx">
      <UserIdentifyProvider data-sentry-element="UserIdentifyProvider" data-sentry-source-file="index.tsx">
        <SyncTokenProvider data-sentry-element="SyncTokenProvider" data-sentry-source-file="index.tsx">
          <TicketLoadProvider data-sentry-element="TicketLoadProvider" data-sentry-source-file="index.tsx">
            <GeoLocationProvider geoLocation={geoLocation} data-sentry-element="GeoLocationProvider" data-sentry-source-file="index.tsx">
              <CartLoadProvider data-sentry-element="CartLoadProvider" data-sentry-source-file="index.tsx">
                <ElevarProvider data-sentry-element="ElevarProvider" data-sentry-source-file="index.tsx">
                  <PreviewModeProvider data-sentry-element="PreviewModeProvider" data-sentry-source-file="index.tsx">
                    <ErrorMessageBanner data-sentry-element="ErrorMessageBanner" data-sentry-source-file="index.tsx" />
                    {children}
                  </PreviewModeProvider>
                </ElevarProvider>
              </CartLoadProvider>
            </GeoLocationProvider>
          </TicketLoadProvider>
        </SyncTokenProvider>
      </UserIdentifyProvider>
    </ReduxProvider>;
};
export default ProviderTree;