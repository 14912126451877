import React from 'react';
import type { FC } from 'react';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';
import LinkInternal from '../LinkInternal';
const NavbarNoAccount: FC = () => {
  return <>
      <div className="col navbar__link navbar__link--icon d-block d-lg-none flex-grow-0">
        <LinkInternal to={ROUTES.myPlace} ariaLabel="Navigate to My Place" data-sentry-element="LinkInternal" data-sentry-source-file="NavbarNoAccount.tsx">
          <Icon icon="oa-user" data-sentry-element="Icon" data-sentry-source-file="NavbarNoAccount.tsx" />
        </LinkInternal>
      </div>
      <div className="col navbar__link d-none d-lg-flex flex-grow-0">
        <LinkInternal ariaLabel="Navigate to Log in" to={ROUTES.login} data-sentry-element="LinkInternal" data-sentry-source-file="NavbarNoAccount.tsx">
          Log in
        </LinkInternal>
      </div>
      <div className="col navbar__link d-none d-lg-flex flex-grow-0">
        <LinkInternal ariaLabel="Navigate to Register" to={ROUTES.register} data-sentry-element="LinkInternal" data-sentry-source-file="NavbarNoAccount.tsx">
          Register
        </LinkInternal>
      </div>
    </>;
};
export default NavbarNoAccount;