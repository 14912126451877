import React, { memo } from 'react';
import Lottie from 'lottie-react';
import loaderAnimation from './loader-animation.json';
import messages from './messages';
const generateRandomText = (textArray: string[]) => {
  const randomIndex = Math.floor(Math.random() * textArray.length);
  return textArray[randomIndex];
};
const PlayerLoader = () => {
  return <div className="h-full relative" data-sentry-component="PlayerLoader" data-sentry-source-file="player-loader.tsx">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <Lottie animationData={loaderAnimation} loop data-sentry-element="Lottie" data-sentry-source-file="player-loader.tsx" />
        <div className="mt-[1rem] center">
          <div className="body-title text-center after:animate-ellipsis after:inline-block after:w-2 ">
            {generateRandomText(messages.loading_messages)}
          </div>
        </div>
      </div>
    </div>;
};
export default memo(PlayerLoader);