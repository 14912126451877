export default {
  my_place: 'My Place',
  profile: 'Profile',

  side_navigation: {
    my_place: 'My Place',
    shows: 'Shows',
    profile_settings: 'Settings',
    log_out: 'Log out',
  },

  main: {
    my_place: 'My Place',
    personal_information: 'You’ll find all your personal information here',
    hello: 'Hello',
    show_section_title: 'Latest purchase',
    no_shows_purchased_yet: 'Looks like you don’t have any shows yet!',
    link_to_my_shows: 'Your shows',
    browse_our_shows: 'Browse streams',
  },

  profile_settings: {
    change_profile: {
      title: 'Settings',
      primary_cta: 'Save changes',
      change_email_link: 'Change email',
      change_password_link: 'Change password',
      export_my_data_link: 'Export my data',
      request_account_deletion_link: 'Request account deletion',
    },
    change_email: {
      title: 'Change email address',
      primary_cta: 'Change email address',
      secondary_cta: 'Cancel',
    },
    change_password: {
      change_password: 'Change password',
      current_password: 'Current password',
      forgot_password: 'Forgot password?',
      new_password: 'New password',
      cancel: 'Cancel',
    },
  },

  my_tickets: {
    title: 'Current Shows',
    past_events: 'Past Shows',
    error_fetching_tickets:
      'Something is going wrong on our side, the team has been notified about this issue. Please try again later.',
    empty_state_body: 'Looks like you don’t have any active shows!',
    browse_shows: 'Browse streams',
    expired_on: 'Expired on:',
    available_until: 'Available until:',
    available_from: 'Available from:',
  },
};
