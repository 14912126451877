export default {
  support: 'SUPPORT',
  email: 'helpdesk@onair.events',
  contact_information: 'Contact information',
  help: 'Help center / F.A.Q.',
  shows: 'SHOWS',
  services: 'SERVICES',
  my_shows: 'My shows',
  browse_shows: 'Browse streams',
  genres: 'Genres',
  company: 'COMPANY',
  press: 'Press',
  team: 'Team',
  tagline:
    'Taking every fan to the front row with the highest quality on-stage entertainment.',
  terms_and_conditions: 'Terms and Conditions',
  copyrights: 'On Air B.V. All Rights Reserved.',
  privacy_policy: 'Privacy Policy',
  cookies: 'Cookies',
  trees: 'We plant trees',

  // Support
  footer_help: 'HELP',
  helpdesk: 'Helpdesk',
  supported_devices: 'Supported Devices',
  payment_methods: 'Payment Methods',
  troubleshooting: 'Troubleshooting',

  // Company
  about_us: 'About On Air',
  contact_us: 'Contact Information',
  blog: 'Blog',
  dolby: 'Dolby',
  apps: 'Apps',

  // Services
  our_services: 'Our Services',
  production: 'Production',
  distribution_and_delivery: 'Distribution & Delivery',
  marketing_and_pr: 'Marketing & PR',

  english: 'English',
  german: 'Deutsch',
  spanish: 'Español',
  french: 'Français',
  dutch: 'Nederlands',
  eu_amsterdam: 'EU/Amsterdam',
  us_new_york: 'US/New York',
};
