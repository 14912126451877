export const cartPageTexts = {
  empty_cart_title: 'Your cart is empty!',
  empty_cart_desc: 'Looks like you haven’t added any shows yet!',
  empty_cart_btn_text: 'Browse streams',
  abandoned_cart_opened_different_country_msg:
    'Your cart items could not be restored because the items in the cart were added in a different country compared to your current country.',
  included: 'included',
  free: 'free',
  all_prices_final: 'all prices are final',
};
