import { createSlice } from '@reduxjs/toolkit';

export interface NavigationState {
  loginReason: '';
  checkoutUrl: '';
  from: '';
  returnTo: '';
}

export const navigateSliceInitialState: NavigationState = {
  loginReason: '',
  checkoutUrl: '',
  from: '',
  returnTo: '',
};

export enum NavigationActionTypes {
  createNavigationParams = 'navigation/create',
}

//@ts-expect-error
export const createNavigationParams = ({ state }) => ({
  type: NavigationActionTypes.createNavigationParams,
  payload: state,
});

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: navigateSliceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      NavigationActionTypes.createNavigationParams,
      // @ts-expect-error
      (state, { payload }) => {
        state.loginReason = payload.loginReason;
        state.checkoutUrl = payload.checkoutUrl;
        state.from = payload.from;
        state.returnTo = payload.returnTo;
      },
    );
  },
});

export const navigateActions = navigationSlice.actions;
