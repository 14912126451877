export default {
  buyShowNow: 'Buy show now',
  buyShow: 'Buy show',
  preOrderShow: 'Pre-order show',
  buyTicket: 'Buy show',
  moreInfo: 'More info',
  goToShow: 'Go to show',
  alreadyBoughtATicket: 'Already bought this show? ', // Keep the space afterwards, link coming after
  loginToSeeYourTicket: 'Log in to access your library',
  stillWorking: `We're still working on adding this event to our website :(`,
  pleaseComeBackLater: `Please come back later...`,
  releaseDate: 'Release date',
  releasedOn: 'Released on',
  onAiron: 'On air on',
  onAirSince: 'On air since',
  airedOn: 'Aired on',
  quality: 'Quality',
};
