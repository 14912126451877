import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { ButtonType } from '@/enums/ButtonType';
import { AuthCustomerInfo } from '@/reducers/authSlice';
import { EventsState } from '@/reducers/eventsSlice';
import { Event } from '@/types/contentful/event';
import { LinkType } from '@/types/Notification';
import ROUTES from '../../../constants/routes';
import Button from '../../Button';
import LinkInternal from '../../LinkInternal';
import SkeletonTheme from '../../SkeletonTheme';
import messages from '../messages';
import { TicketSkeleton } from '../my-tickets/MyTicketsSkeleton';
import Ticket from '../my-tickets/Ticket';
import MyPlaceTitle from '../MyPlaceTitle';
interface Props {
  customerInfo?: AuthCustomerInfo;
  fetchStatus: EventsState['status'];
  ticket?: Event;
}
const MyPlaceIndexComponent: FC<Props> = ({
  customerInfo,
  fetchStatus,
  ticket
}) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  useEffect(() => {
    if (customerInfo) {
      setFirstName(customerInfo.firstName);
      setLastName(customerInfo.lastName);
      setEmail(customerInfo.email);
    }
  }, [customerInfo]);
  return <div className="my-place-overview" data-sentry-component="MyPlaceIndexComponent" data-sentry-source-file="MyPlaceIndexComponent.tsx">
      <MyPlaceTitle testId="my-place-index" data-sentry-element="MyPlaceTitle" data-sentry-source-file="MyPlaceIndexComponent.tsx">
        {messages.main.my_place}
      </MyPlaceTitle>

      <div className="my-place-overview__dark-bg">
        <h5 className="title">
          {messages.main.hello} {firstName}
        </h5>
        <p className="body-text">{messages.main.personal_information}</p>
      </div>

      <section className="my-place-overview__show-section">
        <h4 className="section-title">{messages.main.show_section_title}</h4>

        {fetchStatus === 'IN_PROGRESS' && <SkeletonTheme>
            <TicketSkeleton />
          </SkeletonTheme>}

        {fetchStatus === 'FETCHED' && ticket && <>
            <Ticket key={ticket.variants[0].sku} title={ticket.title} href={ticket.slug} imageUrl={ticket.poster.asset?.url} imageAlt={ticket.poster.alt} startDate={ticket.variants[0]?.eventStart ? new Date(ticket.variants[0]?.eventStart) : undefined} endDate={ticket.variants[0]?.eventEnd ? new Date(ticket.variants[0]?.eventEnd) : undefined} currentDate={new Date()} variantIcons={ticket.variants[0]?.icons || []} />
            <LinkInternal className="body-text my-place-link d-flex" to={`${ROUTES.myPlace}${ROUTES.shows}`} type={LinkType.LINK_TERTIARY} icon="oa-chevron-right">
              {messages.main.link_to_my_shows}
            </LinkInternal>
          </>}

        {fetchStatus === 'FETCHED' && !ticket && <div className="d-flex flex-column">
            <span className="d-block body-text mt-2">
              {messages.main.no_shows_purchased_yet}
            </span>
            <div className="d-flex mt-2">
              <Button type={ButtonType.OUTLINE_SECONDARY} hrefInternal="/" className="d-block body-text text-center" grow>
                {messages.main.browse_our_shows}
              </Button>
            </div>
          </div>}
      </section>

      <section>
        <h4 className="section-title">{messages.profile}</h4>
        <span className="d-block body-title mt-2">
          {firstName} {lastName}
        </span>
        <span className="d-block body-text mt-2">{email}</span>

        <LinkInternal className="mt-3 body-text my-place-link d-flex" to={`${ROUTES.myPlace}${ROUTES.profileSettings}`} type={LinkType.LINK_TERTIARY} icon="oa-chevron-right" data-sentry-element="LinkInternal" data-sentry-source-file="MyPlaceIndexComponent.tsx">
          {messages.side_navigation.profile_settings}
        </LinkInternal>
      </section>
    </div>;
};
export default MyPlaceIndexComponent;