'use client';

import { useCallback, useEffect } from 'react';
import type { FC } from 'react';
import Image from 'next/image';
import useIsMounted from '@/hooks/use-is-mounted';
import { useGeoLocation } from '@/providers/leaf/geo-location';
import { ContentfulEventOption, Event } from '@/types/contentful';
import { useEventConfigurator } from '@/utils/eventConfigurator';
import ROUTES from '../../constants/routes';
import { useBuyTicket } from '../../hooks/useBuyTicket';
import { useAppSelector } from '../../state/hooks';
import type { CartState } from '../../state/reducers/cartSlice';
import { navigate } from '../../utils/navigation';
import LinkInternal from '../LinkInternal';
import StickyHero from '../templates/event-page/Stickyhero';
import HeroButtonAndPrice from './HeroButtonAndPrice';
import HeroEventConfiguratorDate from './HeroEventConfiguratorDate';
import messages from './messages';
import MultipleVariantsConfigurator from './MultipleVariantsConfigurator';
import SingleVariantConfigurator from './SingleVariantConfigurator';
import StickyHeroEventInfo from './StickyHeroEventInfo';
interface HeroEventConfiguratorElementType {
  event: Event;
  releaseDate?: string;
}
const HeroEventConfiguratorElement: FC<HeroEventConfiguratorElementType> = ({
  event,
  releaseDate
}) => {
  const {
    variants,
    slug,
    eventInfoLogo
  } = event;
  const isMounted = useIsMounted();
  const {
    status: cartStatus
  }: CartState = useAppSelector(state => state.cartSlice);
  const isLoggedIn = useAppSelector(state => state.auth.loginStatus === 'LOGGED_IN');
  const customerCountryCode = useGeoLocation();
  const {
    availableOptions,
    selectedOptions,
    selectedVariant,
    onSelectOption
  } = useEventConfigurator({
    variants
  });
  const {
    addItemToCart
  } = useBuyTicket(event, selectedVariant);
  const handleOnSelectOption = useCallback((option: ContentfulEventOption) => {
    onSelectOption(option);
  }, [customerCountryCode, event, variants, onSelectOption]);
  useEffect(() => {
    if (cartStatus === 'ADD_CART_LINES_SUCCESS' || cartStatus === 'CREATE_CART_SUCCESS') {
      navigate(ROUTES.cart);
    }
  }, [cartStatus]);
  const availableOptionsArray = [...availableOptions];
  const isSingleVariant = variants.length === 1;
  const eventInfoImage = eventInfoLogo!.asset;
  const alt = eventInfoLogo?.alt || eventInfoLogo?.asset?.title || eventInfoLogo?.asset?.description || '';
  return <>
      {/* Render only if isMounted in order to prevent
          "navigator" is not available during server side rendering error. */}
      {isMounted && selectedVariant && <StickyHero>
          <StickyHeroEventInfo eventTitle={event.shortTitle ?? event.title} selectedVariant={selectedVariant} customerCountryCode={customerCountryCode} cartStatus={cartStatus} handleBuyTicketNow={addItemToCart} />
        </StickyHero>}
      <div className="hero__content" key={`hero-event-info-${slug}`}>
        {eventInfoImage && <Image width={(eventInfoImage as any).width} height={(eventInfoImage as any).height} className="hero__event-image" src={(eventInfoImage as any).url} alt={alt} style={{
        objectFit: 'contain',
        objectPosition: 'left'
      }} />}

        <div className="mr-auto event-configurator">
          <div className="d-flex flex-row justify-content-center justify-content-md-start mt-4 mb-1 w-100">
            <HeroEventConfiguratorDate isSingleVariant={isSingleVariant} releaseDate={releaseDate} data-sentry-element="HeroEventConfiguratorDate" data-sentry-source-file="HeroEventConfiguratorElement.tsx" />

            {isSingleVariant && <SingleVariantConfigurator variants={variants} />}
          </div>

          {!isSingleVariant && <MultipleVariantsConfigurator availableOptionsArray={availableOptionsArray} selectedOptions={selectedOptions} onSelectOption={handleOnSelectOption} />}

          {selectedVariant && <HeroButtonAndPrice selectedVariant={selectedVariant} handleBuyTicketNow={addItemToCart} isLoading={cartStatus === 'CREATE_CART_PENDING' || cartStatus === 'ADD_CART_LINES_PENDING'} />}
          {!isLoggedIn && <p className="login-to-see-your-ticket">
              {messages.alreadyBoughtATicket}
              <LinkInternal to={ROUTES.login}>
                {messages.loginToSeeYourTicket}
              </LinkInternal>
            </p>}
        </div>
      </div>
    </>;
};
export default HeroEventConfiguratorElement;