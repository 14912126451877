import React, { FC } from 'react';
import Image from 'next/image';
import Socials from '@/components/Socials';
import { SocialMediaType } from '@/enums/SocialMediaPlatforms';
import { ContentfulImage } from '@/types/contentful';
import { LinkType } from '@/types/Notification';
import { getUrlOfSocialMediaPost } from '@/utils/postOnSocialMedia';
import { onAirSocialsLinks } from '@/utils/social';
import LinkExternal from '../../LinkExternal';
import messages from './messages';
type PastEventProps = {
  image?: ContentfulImage;
  eventTitle: string;
};
const PastEventPurchasedComponent: FC<PastEventProps> = ({
  image,
  eventTitle
}) => {
  const {
    past_event_purchased: {
      title,
      pre_text,
      after_text,
      body_text
    }
  } = messages;
  const eventInfoImage = image && image?.asset?.file?.url;
  return <div className="past-event-purchased-wrapper hero__content" data-sentry-component="PastEventPurchasedComponent" data-sentry-source-file="PastEventPurchased.tsx">
      {eventInfoImage && <Image fill src={eventInfoImage} alt={image?.alt || ''} className="hero__event-image mx-auto" objectFit="contain" />}
      <p className="mt-4 subtitle">{title}</p>
      <p className="mt-2 mt-md-3 body-text">
        {pre_text}
        {eventTitle}
        {after_text}
      </p>
      <p className="mt-2 mb-2 mt-md-3 body-text">{body_text} </p>
      <div className="past-event-social-links">
        <Socials links={onAirSocialsLinks} data-sentry-element="Socials" data-sentry-source-file="PastEventPurchased.tsx" />
      </div>
    </div>;
};
export default PastEventPurchasedComponent;