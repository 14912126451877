import { useCallback, useState } from 'react';
import type { ReactNode } from 'react';
import Image from 'next/image';
import Button from '../Button';
interface Props {
  thumbnailImageUrl?: string;
  alt?: string;
  children: ReactNode;
}
/**
 * Display an Image with a play button. Wrap the player with it and render the player only if the play button is pressed.
 */
const ThumbnailImage = ({
  thumbnailImageUrl,
  alt,
  children
}: Props) => {
  const [showThumbnail, setShowThumbnail] = useState(true);
  const thumbnailImage = thumbnailImageUrl ?? '/assets/images/login-register-bg.png';
  const handlePlayPress = useCallback(() => {
    setShowThumbnail(false);
  }, []);
  if (!showThumbnail) return <>{children}</>;
  return <div className="thumbnail-image-container" data-sentry-component="ThumbnailImage" data-sentry-source-file="ThumbnailImage.tsx">
      <Button className="play-icon" onClick={handlePlayPress} icon="oa-play" data-sentry-element="Button" data-sentry-source-file="ThumbnailImage.tsx" />
      <Image fill src={thumbnailImage} alt={alt ?? ''} className="thumbnail-image" objectFit="contain" data-sentry-element="Image" data-sentry-source-file="ThumbnailImage.tsx" />
    </div>;
};
export default ThumbnailImage;