'use client';

import React, { createContext, FC, useContext } from 'react';
import { DEFAULT_COUNTRY_CODE } from '@/utils/i18n';
interface Props {
  children: React.JSX.Element;
  geoLocation?: string | null;
}
const GeoLocationContext = createContext<string | null>(null);
const GeoLocationProvider: FC<Props> = ({
  children,
  geoLocation
}) => {
  return <GeoLocationContext.Provider value={geoLocation ?? DEFAULT_COUNTRY_CODE} data-sentry-element="unknown" data-sentry-component="GeoLocationProvider" data-sentry-source-file="index.tsx">
      {children}
    </GeoLocationContext.Provider>;
};
export function useGeoLocation() {
  const geoLocation = useContext(GeoLocationContext);
  if (geoLocation === null) {
    throw new Error('Geo location context not available');
  }
  return geoLocation;
}
export default GeoLocationProvider;