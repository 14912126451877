import { commonTexts } from '@/messages/common';

/* eslint-disable no-template-curly-in-string */
export default {
  notFound: {
    doc_title: `Page not found · ${commonTexts.company_name}`,
    doc_description: `${commonTexts.company_name} - all things live`,
    title: '404 Error',
    message: 'Sorry, we can’t find what you’re looking for!',
    option_title:
      'You’ve landed on a page that doesn’t exist. Please try the following:',
    option_help: '${link} if you think this is an error on our end', // Ensure the link placeholder is handled later'
    option_help_link: 'Let us know',
    option_home: 'If you’d just like to browse, visit our ${link}', // Handle the link placeholder later
    option_home_link: 'homepage',
  },
};
